<template>
  <div :aria-label="formattedRate" class="flex w-full items-center py-5">
    <div
      v-for="indexFull in nbFullDots"
      :key="`${indexFull}full`"
      aria-hidden="true"
      class="bg-action-success-hi ml-4 inline-block h-8 w-8 rounded-[50%] first:ml-0"
    />

    <div
      v-if="displayHalfDot"
      aria-hidden="true"
      class="bg-action-success-hi rounded-round relative ml-4 inline-block h-8 w-8"
    >
      <div
        class="bg-static-default-hi rounded-round absolute right-0 top-0 inline-block h-8 w-4 rounded-l-none"
      ></div>
    </div>

    <div
      v-for="indexEmpty in nbEmptyDots"
      :key="`${indexEmpty}empty`"
      aria-hidden="true"
      class="bg-static-default-hi rounded-round ml-4 inline-block h-8 w-8"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { MAX_RATING } from '../../constants'

import { useRatingDetailsDots } from './useRatingDetailsDots'

const props = defineProps<{ rate: number }>()

const { displayHalfDot, nbEmptyDots, nbFullDots } = useRatingDetailsDots(
  props.rate,
)

const formattedRate = computed(() => {
  return `${props.rate}/${MAX_RATING}`
})
</script>
