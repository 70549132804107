<template>
  <component :is="orderlineContentComponent" v-bind="props" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import OrderlineTimeline from '@/scopes/care-commons/components/order/orderlineTimeline/OrderlineTimeline.vue'

import OrderlineCardCancel from './components/OrderlineCardCancel/OrderlineCardCancel.vue'
import OrderlineCardRefund from './components/OrderlineCardRefund/OrderlineCardRefund.vue'
import OrderlineCardReplaced from './components/OrderlineCardReplaced/OrderlineCardReplaced.vue'

export interface OrderlineCardContentProps {
  orderlineState: Orderline['state']
  refundDate: Orderline['refundDate']
  timeline: Orderline['timeline'] | null
  replacementOrderline: Orderline['replacedByReplacementOrderline']
}

const props = defineProps<OrderlineCardContentProps>()

const orderlineContentComponent = computed(() => {
  if (!isEmpty(props.timeline)) {
    return OrderlineTimeline
  }

  if (props.orderlineState.enumState === 'REPLACED') {
    return OrderlineCardReplaced
  }

  if (isEmpty(props.refundDate)) {
    return null
  }

  if (props.orderlineState.enumState === 'REFUNDED') {
    return OrderlineCardRefund
  }

  if (props.orderlineState.enumState === 'CANCELLED') {
    return OrderlineCardCancel
  }

  return null
})
</script>
