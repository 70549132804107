export default {
  title: {
    id: 'dashboard_order_review',
    defaultMessage: 'My review for this order',
  },
  showMore: {
    id: 'show_more',
    defaultMessage: 'Show more',
  },
}
