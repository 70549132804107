export default {
  content: {
    id: 'dashboard_order_see_past_orders_content',
    defaultMessage:
      "We've recently moved our site so, you may not see previous orders on this page. Simply click below to find more orders.",
  },
  cta: {
    id: 'dashboard_order_see_past_orders_button',
    defaultMessage: 'See past orders',
  },
  title: {
    id: 'dashboard_order_see_past_orders_title',
    defaultMessage: 'Not seeing a previous order?',
  },
}
