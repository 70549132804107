<template>
  <div>
    <RevButton
      v-if="!orderlineAction.hint"
      :data-qa="`orderline-action-${orderlineAction.type}`"
      :disabled="!orderlineAction.active"
      full-width="always"
      :variant="variant"
      @click="performAction"
    >
      {{ orderlineAction.label }}
    </RevButton>

    <RevTooltip v-if="orderlineAction.hint">
      <template #trigger="{ show, hide }">
        <RevButton
          :data-qa="`orderline-action-${orderlineAction.type}`"
          :disabled="!orderlineAction.active"
          full-width="always"
          :variant="variant"
          @click="performAction"
          @focusin="show"
          @focusout="hide"
          @mouseleave="hide"
          @mouseover="show"
        >
          {{ orderlineAction.label }}
        </RevButton>
      </template>

      {{ orderlineAction.hint }}
    </RevTooltip>
  </div>
</template>

<script lang="ts" setup>
import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { RevButton } from '@ds/components/Button'
import { RevTooltip } from '@ds/components/Tooltip'

import { useOrderlineCardActionsConfig } from '../../../composables/useOrderlineCardActionsConfig'

export interface OrderlineCardActionProps {
  orderlineAction: Orderline['actions'][number]
  orderlineId: Orderline['orderlineId']
  publicId: Orderline['publicId']
  variant: 'primary' | 'secondary'
}

const props = defineProps<OrderlineCardActionProps>()

const { config: orderlineCardActionsConfig } = useOrderlineCardActionsConfig()

async function performAction(): Promise<void> {
  const config = orderlineCardActionsConfig[props.orderlineAction.type](
    props.orderlineAction,
  )

  if (config.onClick) {
    const { onClick } = config

    await onClick({
      orderlineId: props.orderlineId,
      publicId: props.publicId,
    })
  }
}
</script>
