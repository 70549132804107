import type { ProductRatingDetailsIdentifierWithoutAttachments } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import translations from './RatingDetails.translations'

export const detailTitleByIdentifier: Record<
  ProductRatingDetailsIdentifierWithoutAttachments,
  I18nDefinition
> = {
  review_accessories: translations.accessoriesTitle,
  review_aesthetic_appearance: translations.aestheticAppearanceTitle,
  review_battery: translations.batteryTitle,
  review_camera: translations.cameraTitle,
  review_main: translations.mainTitle,
  review_overall_performance: translations.overallPerformanceTitle,
  review_packaging_cleanliness: translations.packagingCleanlinessTitle,
  review_shipping: translations.shippingTitle,
}
