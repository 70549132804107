<template>
  <section class="grid md:grid-cols-2 md:gap-x-56">
    <div>
      <h3 class="heading-3 pb-8">{{ i18n(translations.header) }}</h3>

      <RevButton
        class="w-full"
        data-test="orders-review-create"
        :to="reviewFormLink"
        variant="secondary"
        @click="trackAction"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'

import { useBuildReviewCollectionLink } from '~/scopes/reviews/review-collection/composables/useBuildReviewCollectionLink'

import translations from './NewReview.translations'

const i18n = useI18n()
const props = defineProps<{ orderlineId: number; publicId: string }>()

const { buildReviewCollectionLink } = useBuildReviewCollectionLink()
const reviewFormLink = computed(() => {
  return buildReviewCollectionLink(props)
})
const route = useRoute()
const { trackClick } = useTracking()
const trackAction = () => {
  trackClick({ name: 'start_review', zone: route.name as string })
}
</script>
