<template>
  <div class="flex flex-col md:flex-row">
    <div class="mb-16 md:mt-0 md:w-1/4">
      <RevSkeleton shape="line" width="50%" />
    </div>
    <div class="md:w-9/12">
      <div class="mb-12 overflow-hidden">
        <CommentSkeleton />
      </div>

      <RevDivider class="my-24" />

      <RevSkeleton
        :height="breakpoint > 2 ? '100px' : '60px'"
        shape="rectangle"
        width="100%"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { RevDivider } from '@ds/components/Divider'
import { RevSkeleton } from '@ds/components/Skeleton'

import CommentSkeleton from '../CommentSkeleton/CommentSkeleton.vue'

const breakpoint = useBreakpoint()
</script>
