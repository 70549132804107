<template>
  <ReviewItemOrderlineSkeleton v-if="pending" />

  <ReviewItemOrderline v-else-if="review" v-bind="review" />
</template>

<script lang="ts" setup>
import { watch } from 'vue'

import { getOrderReview } from '@backmarket/http-api/src/api-specs-reviews/order-review'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import ReviewItemOrderline from './ReviewItemOrderline.vue'
import ReviewItemOrderlineSkeleton from './ReviewItemOrderlineSkeleton.vue'

const props = defineProps<{ publicId: string }>()

const logger = useLogger()

const {
  data: review,
  pending,
  error,
} = useHttpFetch(getOrderReview, {
  pathParams: {
    uuid: props.publicId,
  },
})

watch(
  error,
  (e) => {
    if (e) {
      logger.error(
        `[Review] Failed to fetch review for the order with uuid ${props.publicId}`,
      )
    }
  },
  {
    immediate: true,
  },
)
</script>
