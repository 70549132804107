<template>
  <section>
    <h3 class="heading-3 mb-16">{{ i18n(translations.title) }}</h3>

    <div
      ref="reviewItemOrderlineObserverTarget"
      class="flex flex-col md:flex-row"
    >
      <div class="mb-16 md:mt-0 md:w-1/4">
        <RevRating :score="averageRate" size="small" />
      </div>
      <div class="md:w-9/12">
        <div class="mb-12 overflow-hidden">
          <span
            class="body-1 whitespace-pre-line"
            :data-selector="REVIEW_COMMENT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO"
            data-test="reviews-display-reviewitem-comment"
          >
            {{ displayedText }}
          </span>

          <RevLink
            v-if="isCommentHidden && isCommentLong"
            class="ml-16"
            data-test="reviews-display-reviewitem-seemore"
            @click="expandText"
          >
            {{ i18n(translations.showMore) }}
          </RevLink>
        </div>

        <RevDivider class="my-24" />

        <RatingDetails v-if="details?.length" :details="details" />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, onBeforeUnmount, ref, watch } from 'vue'

import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevDivider } from '@ds/components/Divider'
import { RevLink } from '@ds/components/Link'
import { RevRating } from '@ds/components/Rating'
import { useIntersectionObserver } from '@vueuse/core'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import RatingDetails from '../RatingDetails/RatingDetails.vue'

import {
  REVIEW_COMMENT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO,
  TEXT_MAX_SIZE,
} from './ReviewItemOrderline.constants'
import translations from './ReviewItemOrderline.translations'

const props = defineProps<Review>()

const i18n = useI18n()
const route = useRoute()
const { trackReviewImpression } = useTracking()

const isCommentHidden = ref(true)
const reviewItemOrderlineObserverTarget = ref<null | HTMLElement>(null)
const hasTriggeredImpression = ref(false)

const isCommentLong = computed(() => props.comment.length > TEXT_MAX_SIZE)

const displayedText = computed(() => {
  if (isCommentLong.value && isCommentHidden.value) {
    return `${props.comment.substring(0, TEXT_MAX_SIZE)}...`
  }

  return props.comment
})

function expandText() {
  isCommentHidden.value = !isCommentHidden.value
}

const { stop } = useIntersectionObserver(
  reviewItemOrderlineObserverTarget,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const routeName = route.name?.toString() || ''

      trackReviewImpression({
        reviewScore: props.averageRate,
        pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName],
      })

      hasTriggeredImpression.value = true
    }
  },
)

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
