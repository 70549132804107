<template>
  <div v-if="hasActions" class="space-y-12" data-test="mobile-plan-actions">
    <MobilePlanAction
      v-for="(action, index) in validActions"
      :key="action.type"
      :mobile-plan-action="action"
      :orderline-id="orderlineId"
      :variant="getVariant(index)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { ORDERLINE_BUNDLED_MOBILE_PLAN_ACTIONS } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MobilePlanActionsProps } from './MobilePlanActions.types'
import MobilePlanAction from './components/MobilePlanAction.vue'

const props = withDefaults(defineProps<MobilePlanActionsProps>(), {
  onlySecondaryCta: false,
})

const hasActions = !isEmpty(props.mobilePlanActions)

const validActions = computed(() =>
  props.mobilePlanActions.filter((action) =>
    Object.values(ORDERLINE_BUNDLED_MOBILE_PLAN_ACTIONS).includes(action.type),
  ),
)

const getVariant = (index: number) => {
  if (props.onlySecondaryCta) {
    return 'secondary'
  }

  return index === 0 ? 'primary' : 'secondary'
}
</script>
