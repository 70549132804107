<template>
  <RevContainer>
    <div class="mb-32 space-y-4 text-center">
      <h2 class="heading-1">{{ i18n(translations.title) }}</h2>

      <p class="body-1">{{ i18n(translations.subtitle) }}</p>
    </div>

    <div
      v-if="hasOrderlines"
      class="space-y-20 md:space-y-32"
      data-qa="order-list"
    >
      <OrderlineCard
        v-for="orderline in orderlines"
        :key="orderline.orderlineId"
        :orderline="orderline"
      />

      <div v-if="canLoadMore" class="flex items-center justify-center">
        <LazyLoad :is-loading="pending" @load-more="loadMore" />
      </div>
    </div>

    <PastOrdersBanner
      v-if="showPastOrdersBanner"
      class="mt-20"
      data-test="past-orders-banner"
    />

    <div v-if="initialLoading" class="flex items-center justify-center">
      <RevSpinner
        :alternative-text="i18n(translations.loading)"
        data-test="my-orders-initial-loading"
      />
    </div>

    <EmptyOrderlines v-if="hasNoOrderlines" data-qa="empty-orderlines" />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'
import { RevSpinner } from '@ds/components/Spinner'

import { LazyLoad } from '#components'
import OrderlineCard from '@/scopes/care-commons/components/order/OrderlineCard/OrderlineCard.vue'

import translations from './MyOrders.translations'
import EmptyOrderlines from './components/EmptyOrderlines/EmptyOrderlines.vue'
import PastOrdersBanner from './components/PastOrdersBanner/PastOrdersBanner.vue'
import { useOrderlines } from './composables/useOrderlines'

const i18n = useI18n()
const country = useMarketplace().market.countryCode
const { FF_SHOW_PETANQUE_ORDERS_BANNER_BY_COUNTRY } = useRuntimeConfig().public

const { canLoadMore, execute, loadMore, orderlines, pending } = useOrderlines()

await execute()

const hasOrderlines = computed(() => !isEmpty(orderlines.value))

const hasNoOrderlines = computed(() => !hasOrderlines.value && !pending.value)

const initialLoading = computed(() => pending.value && !hasOrderlines.value)

const showPastOrdersBanner = computed(() => {
  return (
    FF_SHOW_PETANQUE_ORDERS_BANNER_BY_COUNTRY.includes(country) &&
    !canLoadMore.value
  )
})
</script>
