import { createHttpEndpoint } from '../utils'

import type { Review } from './types/review'

/**
 * Review for a specific order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/sales-order-item}
 */
export const getOrderReview = createHttpEndpoint<Review>({
  method: 'GET',
  operationId: 'fetchReviewByOrder',
  path: '/reviews/sales-order-item/:uuid/review',
})
