<template>
  <RevInfoBlock
    :content="i18n(translations.content)"
    :cta="i18n(translations.cta)"
    :icon="IconInfo"
    :title="i18n(translations.title)"
    variant="default"
    @click="navigateToPetanqueOrders"
  >
  </RevInfoBlock>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale, useRouter } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconInfo } from '@ds/icons/IconInfo'

import { DASHBOARD } from '../../../routes'

import translations from './PastOrdersBanner.translations'

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()
const router = useRouter()

async function navigateToPetanqueOrders() {
  await router.push(
    toNuxtLink(
      injectLocale({
        type: 'internal',
        name: DASHBOARD.MY_PETANQUE_ORDERS,
      }),
    ),
  )
}
</script>
