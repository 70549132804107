<template>
  <RevModal
    closable
    :name="name"
    :title="i18n(translations.title)"
    variant="confirmation"
  >
    <template #body>
      <p class="heading-1 text-center">
        {{ i18n(translations.subTitle) }}
      </p>

      <p class="body-1 mt-12">
        {{ i18n(translations.content) }}
      </p>

      <picture>
        <RevIllustrationSource
          :height="140"
          :media="BreakpointQueries[Breakpoint.MD]"
          src="/img/common/AppZeloot.svg"
          :width="160"
        />

        <RevIllustration
          alt="cancel-order-pending-payment-modal-illustration"
          class="mx-auto mt-32 md:mt-56"
          :height="100"
          src="/img/common/AppZeloot.svg"
          :width="120"
        />
      </picture>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  Breakpoint,
  BreakpointQueries,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { RevModal } from '@ds/components/Modal'

import { ORDERLINE_CARD_MODAL } from '../../OrderlineCard.constants'

import translations from './CancelOrderPendingPaymentModal.translations'

export interface CancelOrderPendingPaymentModalProps {
  orderlineId: number
}

const props = defineProps<CancelOrderPendingPaymentModalProps>()

const i18n = useI18n()

const name = computed(
  () =>
    `${ORDERLINE_CARD_MODAL.cancelOrderWithPendingPayment}-${props.orderlineId}`,
)
</script>
