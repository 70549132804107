export default {
  mainTitle: {
    id: 'review_main_title',
    defaultMessage: 'Value for money',
  },
  accessoriesTitle: {
    id: 'review_accessories_title',
    defaultMessage: 'Accessories',
  },
  aestheticAppearanceTitle: {
    id: 'review_aesthetic_appearance_title',
    defaultMessage: 'Outside Appearance',
  },
  batteryTitle: {
    id: 'review_battery_title',
    defaultMessage: 'Battery',
  },
  cameraTitle: {
    id: 'review_camera_title',
    defaultMessage: 'Lens & Camera',
  },
  overallPerformanceTitle: {
    id: 'review_overall_performance_title',
    defaultMessage: 'Overall performance',
  },
  packagingCleanlinessTitle: {
    id: 'review_packaging_cleanliness_title',
    defaultMessage: 'Packaging and cleanliness',
  },
  shippingTitle: {
    id: 'review_shipping_title',
    defaultMessage: 'Shipping',
  },
}
