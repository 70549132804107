import { useRuntimeConfig } from '#imports'
import { type RouteLocationNamedRaw } from 'vue-router'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { ROUTES } from '../../route-names'

export function useBuildReviewCollectionLink() {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEW_COLLECTION = '' } =
    useRuntimeConfig().public

  const { market } = useMarketplace()

  function buildReviewCollectionLink(orderline: {
    publicId: string
    orderlineId: string | number
  }) {
    const isReviewCollectionFrontAppsRoutesOpenedInCountry =
      FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEW_COLLECTION.includes(
        market.countryCode,
      )

    const id = isReviewCollectionFrontAppsRoutesOpenedInCountry
      ? orderline.publicId
      : orderline.orderlineId

    return {
      name: ROUTES.FORM,
      params: {
        orderlineId: id,
      },
    } satisfies RouteLocationNamedRaw
  }

  return { buildReviewCollectionLink }
}
