import { type ComputedRef, type Ref, computed, ref } from 'vue'

import { type HttpError } from '@backmarket/http-api'
import { getOrderlineList } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

interface UseOrderlinesReturnValues {
  canLoadMore: ComputedRef<boolean>
  error: Ref<HttpError | null>
  execute: (pageParams?: number) => Promise<void>
  loadMore: () => Promise<void>
  orderlines: Ref<Orderline[]>
  pending: Ref<boolean>
}

export function useOrderlines(): UseOrderlinesReturnValues {
  const maxRetries = 3
  const maxOrdersReached = ref<boolean>(false)
  const orderlines = ref<Orderline[]>([])

  const page = ref<number>(1)

  const retries = ref<number>(0)

  const orderlinesError = ref<HttpError | null>(null)
  const orderlinesPending = ref<boolean>(false)

  const canLoadMore = computed(() => {
    if (orderlinesError.value) {
      return retries.value < maxRetries
    }

    return !maxOrdersReached.value
  })

  const getOrderlines = async (pageParams = 1) => {
    const { data, error, pending, execute } = useHttpFetch(getOrderlineList, {
      queryParams: {
        page: pageParams,
      },
      immediate: false,
    })

    orderlinesPending.value = pending.value

    await execute()

    orderlinesPending.value = pending.value

    if (data.value) {
      maxOrdersReached.value = isEmpty(data.value?.next)

      if (data.value?.results) {
        orderlines.value = [...orderlines.value, ...data.value.results]
      }
    }

    if (error.value) {
      if (page.value > 1) {
        page.value -= 1
      }

      retries.value += 1
    }

    orderlinesError.value = error.value
  }

  const loadMore = async () => {
    page.value += 1

    await getOrderlines(page.value)
  }

  return {
    canLoadMore,
    error: orderlinesError,
    execute: getOrderlines,
    loadMore,
    orderlines,
    pending: orderlinesPending,
  }
}
