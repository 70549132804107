<template>
  <section v-if="hasActions">
    <AskBillModal />
    <h3 class="heading-3 pb-8">{{ i18n(translations.header) }}</h3>

    <div class="grid gap-y-12 md:grid-cols-2 md:gap-x-56">
      <DocumentAction
        v-for="action in actionsWithConfig"
        :key="action.type"
        :action="action"
        @request-order-bill="onRequestOrderBill"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { OrderlineDetailed } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineDetailsResponse'
import { postOrderAskBill } from '@backmarket/http-api/src/api-specs-order-management/orders'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { openModal } from '@ds/components/ModalBase'

import translations from './DocumentActions.translations'
import { MODAL_NAME } from './components/AskBillModal/AskBillModal.constants'
import AskBillModal from './components/AskBillModal/AskBillModal.vue'
import DocumentAction from './components/DocumentAction/DocumentAction.vue'
import {
  type Action,
  useDocumentActionsConfig,
} from './composables/useDocumentActionsConfig'

const { actionsData, orderId } = defineProps<{
  actionsData: OrderlineDetailed['detailedActions']
  orderId: number
}>()

const documentActionsConfig = useDocumentActionsConfig()

const hasRequestedBill = ref(false)

const i18n = useI18n()

const actionsWithConfig = computed(() =>
  actionsData
    // only include actions that we explictily have config for
    .filter((action): action is Action => action.type in documentActionsConfig)
    .map((action) => {
      const getActionConfig = documentActionsConfig[action.type]

      return getActionConfig(action, hasRequestedBill.value)
    }),
)

const hasActions = computed(() => actionsWithConfig.value.length > 0)

function onRequestOrderBill(): void {
  $httpFetch(postOrderAskBill, {
    body: {
      order_id: orderId,
    },
    pathParams: {
      id: orderId,
    },
  })
  openModal(MODAL_NAME)
  hasRequestedBill.value = true
}
</script>
