import type { ExternalLink } from '../../api-models/LinkExternal'
import type { InternalLink } from '../../api-models/LinkInternal'
import type { Price } from '../../api-models/Price'
import type { DateTime } from '../../standards/DateTime'

import type {
  OrderlineBundledMobilePlan,
  OrderlineLink,
  OrderlineTimeline,
  Tag,
} from './common'

export type ActionType =
  | 'cancel_order_before_validation'
  | 'cancel_order_pending_payment'
  | 'diagnosis_new_issue'
  | 'diagnosis'
  | 'get_more_help'
  | 'join_conversation'
  | 'live_chat'
  | 'order_bill'
  | 'pending_order_bill'
  | 'rate_order'
  | 'sale_certificate'
  | 'see_return_confirmation'
  | 'track_parcel'
  | 'track_repair_not_picked_up'
  | 'track_repair_picked_up'
  | 'track_shop_repair'

export const ORDERLINE_ACTION: Record<string, ActionType> = {
  cancelOrderBeforeValidation: 'cancel_order_before_validation',
  cancelOrderPendingPayment: 'cancel_order_pending_payment',
  diagnosisNewIssue: 'diagnosis_new_issue',
  diagnosis: 'diagnosis',
  getMoreHelp: 'get_more_help',
  joinConversation: 'join_conversation',
  liveChat: 'live_chat',
  orderBill: 'order_bill',
  pendingOrderBill: 'pending_order_bill',
  rateOrder: 'rate_order',
  saleCertificate: 'sale_certificate',
  seeReturnConfirmation: 'see_return_confirmation',
  trackParcel: 'track_parcel',
  trackRepairNotPickedUp: 'track_repair_not_picked_up',
  trackRepairPickedUp: 'track_repair_picked_up',
  trackShopRepair: 'track_shop_repair',
}

export type OrderlineState = {
  enumState:
    | 'PENDING_PAYMENT'
    | 'PAID'
    | 'VALIDATED'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'CANCELLED'
    | 'REFUNDED'
    | 'NOT_PAID'
    | 'AWAITING_CONFIRMATION'
    | 'REPLACED'
    | 'UNKNOWN'
  date: string | null
  label: string | null
  labelClass: 'DEFAULT' | 'SUCCESS' | 'DANGER' | 'WARNING' | null
}

export type Orderline = {
  orderlineId: number
  /**
   * Publicid
   * @format uuid
   */
  publicId: string
  state: OrderlineState
  orderId: number
  creationDate: DateTime
  canBeRefundedAutomatically: boolean
  refundDate: DateTime | null
  product: {
    title: string
    image: string
    link: InternalLink
  }
  price: Price | null
  timeline: OrderlineTimeline[] | null
  actions: {
    type: ActionType
    active: boolean
    label: string
    hint: string | null
    link: InternalLink | ExternalLink | null
    downloadable: boolean
  }[]
  bundledMobilePlan: OrderlineBundledMobilePlan | null
  replacedByReplacementOrderline: OrderlineLink | null
  tag: Tag | null
}

export type OrderlineListResponse = {
  count: number
  next: string | null
  previous: string | null
  results: Orderline[]
}
